.invite-dialog-content-actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.invite-url {
  border-radius: 0.4rem;
  border: 0.1rem solid var(--border);
  padding: 0.4rem 1.4rem;
}

.invite-dialog-message {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  font-size: 1rem;
  margin-bottom: 1rem;
}
