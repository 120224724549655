.comments-container-header-title {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border: 0.1rem solid var(--border);
  border-radius: 0.2rem;
  text-align: center;
  font-weight: 500;
  width: 100%;
}

abbr {
  text-decoration: none;
  cursor: pointer;
}

.comments-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0.5rem;
  width: 100%;
  max-height: 75vh;
  overflow-y: scroll;
}

.comments-container-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.comments-section-header {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  font-weight: 500;
}

.comments-section-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.comments-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.player-comment {
  border: 0.1rem solid var(--border);
  border-radius: 0.4rem;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  cursor: pointer;
  box-shadow: none;
  transition-duration: 150ms;
  font-family: "Inter", sans-serif;
}

.player-comment:hover {
  transform: translateY(-0.2rem) translateX(-0.2rem);
}

.player-comment-selected {
  box-shadow: 0px 0px 0.2rem 0.01rem var(--border);
  transform: translateY(-0.2rem) translateX(-0.2rem);
}

.player-comment-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  justify-content: space-between;
}

.player-comment-author {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}

.player-comment-avatar {
  width: 2rem;
  object-fit: contain;
  border: 2px solid;
  border-radius: 50%;
}

.player-comment-content {
  text-overflow: ellipsis;
}

.player-comment-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}

.player-comment-emoji-tag {
  border: 0.1rem solid var(--border);
  border-radius: 0.8rem;
  padding: 0.1rem 0.5rem;
  font-family: "Space Grotesk", sans-serif;
}

.player-comment-line-tag {
  font-weight: normal;
}
