input {
  width: fit-content;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Space Grotesk', sans-serif;
  padding: 0.4rem 1.4rem;
  border-radius: 0.4rem;
  color: var(--primary);
  background-color: var(--bg);
  border: 0.1rem solid var(--border);
  outline: none;
  cursor: pointer;
}

input[type="submit"] {
  width: fit-content;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Space Grotesk', sans-serif;
  padding: 0.4rem 1.4rem;
  border-radius: 0.4rem;
  color: var(--primary);
  background-color: var(--bg);
  border: 0.1rem solid var(--border);
  outline: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  transition-duration: 100ms;
  transform: translateY(-0.2rem);
}

.home-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

@media only screen and (max-width: 812px) {
  .home-container {
    flex-direction: column;
    gap: 2rem;
  }
}

.home-left {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headline {
  display: flex;
  flex-direction: column;
}

.tagline {
  font-family: 'Space Grotesk', sans-serif;
}

.home-right {
  border-radius: 1rem;
  height: 512px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

