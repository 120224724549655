.code-container {
  max-width: 75%;
  min-width: 75%;
  height: 100%;
  font-size: 12px;
  border-radius: 0.2rem;
  border: 0.1rem solid var(--border);
}

@media only screen and (max-width: 992px) {
  .code-container {
    margin-top: 1rem;
    margin-left: 0rem;
    max-width: 100%;
  }
}

.selected-line {
  background-color: var(--line-selection-highlight);
  border-left: solid var(--line-selection-border-color);
}

.comment-highlighted-line {
  background-color: var(--line-commented);
  border-left: solid var(--line-commented-bar);
}
