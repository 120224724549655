.navbar {
  display: flex;
  flex-direction: row;
  font-family: 'Space Grotesk', sans-serif;;
  justify-content: space-between;
  align-items: center;
  color: var(--primary);
  max-height: 10vh;
  min-height: 10vh;
  font-size: 1.5rem;
  font-weight: bold;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.links {
  display: flex;
  column-gap: 1rem;
  justify-content: space-around;
}

.icon {
  cursor: pointer;
}
