.help-card {
  width: fit-content;
  display: flex;
  line-height: 1.5rem;
}

.help-list {
  font-size: 0.8rem;
  list-style: square;
}

.help-video-container video {
  max-height: 512px;
}
