@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

/* Define minimum font size */
html {
  font-size: 12px;
}

/* Fluid typography formula */
@media (min-width: 640px) and (max-width: 1439px) {
  html {
    font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1439 - 640));
  }
}

/* Define max font size */
@media (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}

:root {
  --bg: white;
  --border: rgb(208, 215, 222);
  --button: #f2f5f6;
  --primary: black;
  --secondary: #eaebeb;
  --accent: #0068ff;
  --warning: #ef0100;
  --line-selection-highlight: #fff8c5;
  --line-selection-border-color: #eed888;
  --line-commented: rgb(217, 234, 211);
  --line-commented-bar: rgb(147, 196, 125);
  --border-dark-shade: rgb(101 119 134 / 20%);
  --border-light-shade: rgb(101 119 134 / 15%);
}

[data-theme="dark"] {
  --bg: #131415;
  --border: rgb(48, 54, 61);
  --button: #0d1117;
  --primary: white;
  --secondary: #323332;
  --accent: #ff7c7c;
  --warning: #ef0100;
  --line-selection-highlight: #202128;
  --line-selection-border-color: #8f8f8f;
  --line-commented: #0f1b2d;
  --line-commented-bar: #0091ff;
  --border-dark-shade: rgba(255, 255, 255, 0);
  --border-light-shade: rgba(255, 255, 255, 0.075);
}

body {
  background-color: var(--bg);
  color: var(--primary);
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--primary);
}

a:hover {
  color: var(--accent);
}

button {
  width: fit-content;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Space Grotesk", sans-serif;
  padding: 0.4rem 1.4rem;
  border-radius: 0.4rem;
  color: var(--primary);
  background-color: var(--bg);
  border: 0.1rem solid var(--border);
  outline: none;
  cursor: pointer;
}

button:enabled:hover {
  transition-duration: 100ms;
  transform: translateY(-0.2rem);
}

button:disabled {
  opacity: 60%;
}

button:disabled:hover {
  color: var(--primary);
}

textarea {
  border: none;
  border-radius: 0.3rem;
  outline: none;
  width: max-content;
  padding: 0.5rem;
  color: var(--primary);
  background-color: var(--bg);
  border: 0.1rem solid var(--border);
  border-radius: 0.2rem;
  font-weight: 400;
  font-family: "Space Grotesk", sans-serif;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

video {
  height: 100%;
  object-fit: fill;
  border-radius: 1rem;
}

::-webkit-scrollbar {
  height: 0.25rem;
  width: 0.25rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
}

.context-menu {
  position: absolute;
  border-radius: 0.2rem;
  background-color: var(--bg);
  border: 0.1rem solid var(--border);
  /* Context menu is a layer above all other elements, but below dialog (see Dialog's CSS classes) */
  z-index: 1;
}

.context-menu-container {
  font-weight: bold;
}

.context-menu-content {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.context-menu-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.4rem;
  border-top: 0.1rem solid var(--border);
  padding: 0.6rem 0.4rem;
}

.context-menu-button {
  border: 0.1rem solid var(--border);
  border-radius: 0.4rem;
  background-color: var(--bg);
  color: var(--primary);
  font-size: 1rem;
  padding: 0.2rem;
}

.context-menu-button:enabled {
  opacity: 90%;
}

.context-menu-button:enabled:hover {
  opacity: 100%;
  transform: scale(1.2);
  cursor: pointer;
}

.context-menu-header {
  font-size: 1rem;
  font-weight: bold;
  font-family: "Space Grotesk", sans-serif;
}
