.app {
  display: flex;
  flex-direction: column;
  /* All elements are at z-index 0; unless overwritten */
  z-index: 0;
}

.main-section {
  padding-top: 0vh;
}


@media only screen and (min-width: 1200px) {
  .app {
    min-width: 90vw;
    max-width: 90vw;
    margin: 0rem 5vw;
  }
}

@media only screen and (max-width: 1199px) {
  .app {
    min-width: 95vw;
    max-width: 95vw;
    margin: 0rem 2.5vw;
  }
  .app::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .app {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
