.mid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
}

@media only screen and (max-width: 500px) {
  .mid {
    flex-direction: column;
  }
  .players-container {
    max-width: calc(100% - 2rem);
    min-width: calc(100% - 2rem);
  }
}

@media only screen and (max-width: 878px) {
  .mid {
    flex-direction: column;
    gap: 2rem
  }
  .players-container {
    max-width: calc(100% - 2rem);
    min-width: calc(100% - 2rem);
  }
  .comments-container {
    padding: 0rem !important;
  }
}

.container {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 992px) {
  .container {
    flex-direction: column;
    gap: 2rem;
  }
}

.top {
  padding: 1rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.top > a {
  font-family: 'Space Grotesk', sans-serif;
  color: var(--accent);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
}

.top > a:hover {
  text-decoration: underline;
}

.top-right {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.top-btns {
  display: flex;
  flex: row;
  gap: 1rem;
  min-height: 4rem;
  align-items: flex-end;
}

.advance-btn {
  width: 6rem;
}