.dialog-overlay {
  position: fixed;
  inset: 0px;
  backdrop-filter: blur(0.2rem);
  /* Dialog needs to be the top most layer */
  z-index: 999;
}

.dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialog-close {
  cursor: pointer;
}

dialog {
  color: var(--primary);
  background-color: var(--bg);
  outline: none;
  border: 0.1rem solid var(--border);
  border-radius: 0.4rem;
  box-shadow: 0rem 2rem 4rem -1rem rgb(0 0 0 / 0.25);
  overflow-y: scroll;
  max-height: 640px;
  position: absolute;
  inset: 0px;
  padding: 1rem 2rem;
  /* Dialog needs to be the top most layer */
  z-index: 999;
}
