.lobby-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.5rem;
}

.avatar-image {
  width: 2.2rem;
  border: 1.5px solid;
  border-radius: 50%;
  vertical-align: top;
}

.avatar {
  text-align: center;
}

.avatar:hover {
  cursor: pointer;
}
